<template>
    <div>
        <p>
            {{ text.settings }}
        </p>
    </div>
</template>

<script>
    "use strict";

    import config from "@/settings/config.js";
    import displayOrder from "@/settings/displayOrder.js";
    import management from "@/settings/management.js";
    import module from "@/settings/module.js";

    import firebase from "@/mixins/firebase.js";

    export default {
        "mixins": [
            firebase,
        ],
        "props": {
            "name": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "text": {
                    "settings": "開始",
                },
            };
        },
        created() {
            this.update({
                "updateData": {
                    "configs": config,
                    "displayOrders": displayOrder,
                    "managements": management,
                    "modules": module,
                },
                "collection": "settings",
            });
        },
        "methods": {
            update(data) {
                const updateData = data.updateData;
                const collection = data.collection;
                const mergeUpdate = (readData) => {
                    const mergeData = {};
                    for (const doc in updateData) {
                        mergeData[doc] = {};
                        for (const key in updateData[doc]) {
                            mergeData[doc][key] = {
                                ...updateData[doc][key],
                                ...readData[doc][key],
                            };
                            console.log(mergeData[doc][key]);
                        }
                    }
                    this.text[collection] = "結合完了";
                    this.$_firestoreBatch({
                        "collection": collection,
                        "set": mergeData,
                    }).then(() => {
                        this.text[collection] = "アップデート成功";
                    }).catch(error => {
                        this.text[collection] = "アップデート失敗";
                        console.error(error.message);
                    });
                };
                this.$_firestoreRead({
                    "collection": collection,
                }).then(response => {
                    this.text[collection] = "読込完了";
                    const readData = this.$_firestoreReadListObject(response);
                    mergeUpdate(readData);
                }).catch(() => {
                    this.text[collection] = "読込失敗";
                    mergeUpdate(updateData);
                });
            },
        },
    };
</script>
