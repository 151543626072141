"use strict";

import firebase from "firebase";

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.functions();

// 入れ子の処理
const setNest = data => {
    let nest = firestore;
    if (!data.collectionGroup) {
        const collection = data.collection.split("/");
        const doc = data.doc;
        for (const index in collection) {
            if (collection[index]) {
                nest = nest.collection(collection[index]);
            }
            if (doc[index]) {
                nest = nest.doc(doc[index]);
            }
        }
    }
    else {
        const collectionGroup = data.collectionGroup;
        nest = nest.collectionGroup(collectionGroup);
    }
    // 絞り込み
    if (data.where) {
        const where = data.where;
        for (const index in where) {
            nest = nest.where(where[index][0], where[index][1], where[index][2]);
        }
    }
    return nest;
};

export default {
    data() {
        return {
            "recaptcha": {
                "verifier": null,
            },
        };
    },
    "methods": {
        // サインアップ
        $_signUp(mailAddress, password) {
            return auth.createUserWithEmailAndPassword(mailAddress, password);
        },
        // サインイン
        $_signIn(mailAddress, password) {
            return auth.signInWithEmailAndPassword(mailAddress, password);
        },
        // サインアウト
        $_signOut() {
            return auth.signOut();
        },
        // reCAPTCHA
        $_setRecaptcha(refName, option) {
            auth.languageCode = "ja";
            this.recaptcha.verifier = new firebase.auth.RecaptchaVerifier(this.$refs[refName], option);
            this.recaptcha.verifier.render();
        },
        // 確認コード
        $_phoneSignInCode(tel) {
            const code = "+81";
            const phoneNumber = code + tel.slice(1);
            return auth.signInWithPhoneNumber(phoneNumber, this.recaptcha.verifier);
        },
        // UID取得
        $_getUid() {
            return auth.currentUser.uid;
        },
        // タイムスタンプ
        $_firestoreTimestamp() {
            return firebase.firestore.FieldValue.serverTimestamp();
        },
        // バッチ
        $_firestoreBatch(data) {
            const batch = firestore.batch();
            const collection = data.collection;
            for (const doc in data.set) {
                batch.set(firestore.collection(collection).doc(doc), data.set[doc]);
            }
            return batch.commit();
        },
        // スナップショット
        $_firestoreSnapshot(data) {
            const database = setNest(data);
            const callback = data.callback;
            database.onSnapshot(callback);
        },
        // ドキュメント作成
        $_firestoreCreate(data) {
            const database = setNest(data);
            const collection = data.collection.split("/");
            const doc = data.doc;
            const set = data.set;
            set.createdAt = this.$_firestoreTimestamp();
            if (collection.length === doc.length) {
                return database.set(set);
            }
            else {
                return database.add(set);
            }
        },
        // ドキュメント取得
        $_firestoreRead(data) {
            const database = setNest(data);
            return database.get();
        },
        // ドキュメント更新
        $_firestoreUpdate(data) {
            const database = setNest(data);
            const set = data.set;
            set.updatedAt = this.$_firestoreTimestamp();
            return database.update(set);
        },
        // ドキュメント削除
        $_firestoreDelete(data) {
            const database = setNest(data);
            return database.delete();
        },
        // ドキュメント一覧を配列に変換
        $_firestoreReadListArray(response) {
            const list = [];
            for (const key in response) {
                const data = response[key];
                const id = {};
                if (data.userId) {
                    id.userId = data.userId;
                }
                id.updateId = key;
                data.id = id;
                list.push(data);
            }
            return list;
        },
        // ドキュメント一覧を連想配列に変換
        $_firestoreReadListObject(response) {
            const list = {};
            response.forEach(doc => {
                const docId = doc.id;
                list[docId] = doc.data();
            });
            return list;
        },
        // ストレージ保存
        $_storagePut(data) {
            const child = data.child;
            const put = data.put;
            return storage.ref().child(child).put(put);
        },
        // ストレージ取得
        $_storageGet(data) {
            const child = data.child;
            return storage.ref().child(child).getDownloadURL();
        },
        // ストレージ削除
        $_storageDelete(data) {
            const child = data.child;
            return storage.ref().child(child).delete();
        },
        // メール送信
        $_functionsSendMail(data) {
            const mailer  = functions.httpsCallable("sendMail");
            return mailer(data);
        },
    },
};
